import React, { useState } from "react";
import {
  Box,
  Container,
  Typography,
  Card,
  TextField,
  Button,
  CardContent,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useNavigate , useLocation } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useSelector } from "react-redux";
import ToastNotification, { showSuccessToast, showErrorToast } from "../components/ToastNotification";
import api from "../Network/NetworkManager";

const NewPasswordPage = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [loading, setLoading] = useState(false);
  const { resetPasswordStatus } = useSelector((state) => state.auth);
  const location = useLocation();
  const email = location.state?.email || ""; 
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleSubmit = async(e) => {
    e.preventDefault();

    if (formData.newPassword !== formData.confirmPassword) {
      showErrorToast("Passwords do not match.");
      return;
    }

    if (formData.newPassword.length < 6) {
      showErrorToast("Password must be at least 6 characters long.");
      return;
    }

    const data = {
      email : email,
      resetCode : resetPasswordStatus?.data?.resetCode,
      newPassword: formData.newPassword,
    }

    setLoading(true);

    // Simulating API call to reset password
    // axios
    try {
      const response = await api.post("/auth/reset-password", data);
      console.log("response", response);
      setLoading(false);
      showSuccessToast("Password updated successfully.");
      navigate("/login");
    }
    catch (error) {
      console.log("error", error);
      showErrorToast("Failed to reset password. Please try again.");
      setLoading(false);
      return;
    }

    // Simulating API call to set new password
    // setTimeout(() => {
    //   setLoading(false);
    //   showSuccessToast("Password updated successfully.");
    //   navigate("/login");
    // }, 2000);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  }

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundImage: "linear-gradient(135deg, #01AEFE, #42a5f5)",
        padding: "50px 0",
      }}
    >
      <Container maxWidth="sm">
        <Card
          sx={{
            background: "#fff",
            borderRadius: "16px",
            boxShadow: "0 8px 20px rgba(0,0,0,0.2)",
            p: 4,
            textAlign: "center",
            transition: "transform 0.3s",
            "&:hover": {
              transform: "scale(1.05)",
              boxShadow: "0 12px 30px rgba(0,0,0,0.3)",
            },
          }}
        >
          <CardContent>
            <Typography
              variant="h4"
              sx={{ fontWeight: "bold", color: "#1976d2", mb: 3 }}
            >
              Set New Password
            </Typography>

            <Typography variant="body1" sx={{ color: "#666", mb: 4 }}>
              Enter your new password and confirm it to reset your account.
            </Typography>

            <Box component="form" onSubmit={handleSubmit}>
              <TextField
                fullWidth
                label="New Password"
                name="newPassword"
                value={formData.newPassword}
                onChange={handleChange}
                variant="outlined"
                type={showPassword ? "text" : "password"}
                sx={{ mb: 3 }}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={toggleShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                fullWidth
                label="Confirm Password"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                variant="outlined"
                type={showConfirmPassword ? "text" : "password"}
                sx={{ mb: 3 }}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={toggleShowConfirmPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Button
                type="submit"
                variant="contained"
                fullWidth
                disabled={loading}
                sx={{
                  backgroundColor: loading ? "#ccc" : "#1976d2",
                  color: "#fff",
                  fontWeight: "bold",
                  py: 1.5,
                  borderRadius: "8px",
                  fontSize: "1.2rem",
                  "&:hover": {
                    backgroundColor: loading ? "#ccc" : "#1565c0",
                  },
                }}
              >
                {loading ? "Updating..." : "Update Password"}
              </Button>
            </Box>
          </CardContent>

          <Button
            onClick={() => navigate("/login")}
            sx={{
              textTransform: "none",
              color: "#1976d2",
              fontWeight: "bold",
              mt: 2,
              "&:hover": {
                textDecoration: "underline",
                backgroundColor: "transparent",
              },
            }}
          >
            Back to Login
          </Button>
        </Card>
      </Container>
      <ToastNotification />
    </Box>
  );
};

export default NewPasswordPage;
